import { AccordionSummary, Box, Button, Checkbox, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import axios from "axios";
import "./styles/personal-info.css";
import profile from "../../../assets/profile.svg";
import Select from "../../../components/form-components/select";
import Textfield from "../../../components/form-components/text-field";
import DateTimePicker from "../../../components/form-components/date-time-picker";
import UploadButton from "../../../components/form-components/upload-button";
import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { useEffect } from "react";
import { i18n } from "../../../translate/i18n";
import CourseSelection from "./CourseSelection";
import CollapsibleTableVariant from "../../curricular-plan/CollapsibleTableVariant";
import { revertSemesterToListData } from "../../curricular-plan/AddCurricularPlan";

const inputStyles = {
  fontSize: "14px",
  fontWeight: "400",
  color: "#333", // Cor de texto desejada
  width: "470px", // Defina a largura desejada
  height: "35px", // Adicione outros estilos conforme necessário
};


const genders = {
  id_1: "Masculino",
  id_2: "Feminino",
};

const gendersValues = Object.values(genders);
const defaultGender = gendersValues[0];

const PersonalInfo = ({
  id,
  setFiles,
  files,
  errors,
  setFormData,
  values,
  disabled,
  edit,
  setUploadedImage,
  uploadedImage,
  incomingDate,
  handleClick,
  selectedSubjects,
  setSelectedSubjects
}) => {
  console.log("numero", values);
  const [imageId, setImageId] = useState(null);
  const { keycloak } = useKeycloak();
  const [DocumentTypes, setDocumentTypes] = useState([]);
  const [province, setProvince] = useState([]);
  const [municipe, setMunicipe] = useState([]);
  const [listData, setListData] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState("");
  const [lastInputData, setLastInputData] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [openSemester, setOpenSemester] = useState(null);
  const [gender, setGender] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [profilePic, setProfilePic] = useState(null);
  const [isLoadingSubjects, setIsLoadingSubjects] = useState(false);
  const [isSelectedAll, setSelectedAll] = useState(false);

  const [semestersVisible, setSemestersVisible] = useState(false);
  const [visibleSemesters, setVisibleSemesters] = useState({});

  const handleActivateSemester = (semester) => {
    setSelectedSemester(semester);
  };

  const toggleSemester = (semester) => {
    setVisibleSemesters((prev) => ({
      ...prev,
      [semester]: !prev[semester],
    }));
  };
  const isAluno =
    keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles?.includes(
      "Estudante"
    );
  const isSecretary = keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles?.includes("Secretaria")
  const headingText = isAluno
    ? 'Renovação de matrícula'
    : (isSecretary ? i18n.t("sidebar.StudentRegistration") : "Inscreva-se")
  const welcomeText = isAluno
    ? "Inscreva-se para garantir que tenhamos todas as informações necessárias para sua renovação."
    : "Inscreva-se para garantir que tenhamos todas as informações necessárias para sua matrícula, solicitamos que preencha o formulário de dados biográficos abaixo com precisão.";

  const getFile = async (id, name, set) => {
    if (id)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/enrollAttachment/find/${name}/${id}`,
          {
            headers: {
              Authorization: "Bearer " + keycloak.token, // token de autenticação
            },
          }
        );
        console.log("Profile PIC:", response);

        set(response.data?.data ? response.data.data : response.data);
      } catch (error) {
        console.error(error);
      }
  };

  useEffect(() => {

    getFile(id, "ProfilePic", setProfilePic);

  }, [keycloak.token, id]);
  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("ProfilePic", file); // O nome 'file' deve corresponder ao esperado pela API

      const response = await axios.post(
        `${apiUrl}/enrollAttachment`,
        formData,
        {
          "Content-Type": "multipart/form-data", // Importante para enviar arquivos
        }
      );

      // A resposta da API pode conter informações úteis, dependendo do seu caso
      console.log("Resposta da Requisição de Upload:", response);

      // Você pode atualizar o estado com o ID da imagem ou outras informações relevantes da resposta
      setImageId(response.data.imageId);
    } catch (error) {
      console.error("Erro ao fazer upload do arquivo:", error);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFiles((o) => {
        o.ProfilePic = file;
        return o;
      });
    }
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setUploadedImage(e.target.result);
      };

      reader.readAsDataURL(file);

    }
  };

  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token, // token de autenticação
        },
      });
      console.log("Resposta da Requisição:", response);
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStudent(values.acessExam, values.course, values.graduation);
  }, [values.acessExam, values.course, values.graduation]);

  useEffect(() => {
    getList("document-types", setDocumentTypes);
    getList("province", setProvince);
    getList("municipe", setMunicipe);
    getList("gender", setGender);
    getList("marital-status", setMaritalStatus);
  }, []);
  const getStudent = async (id, course, graduation) => {
    await axios
      .get(
        `${apiUrl}/student-evaluated/process/${id}/course/${course}/graduation/${graduation}`
      )
      .then((data) => {
        values.name = data.data?.studentName;
        values.gender = data.data?.gender;
        values.observation = data.data?.observation
        values.courseId = data.data?.courseId
        if (data.data?.observation?.toUpperCase() === "REPETENTE") fetchData();
        setFormData?.({ ...values });
      })
      .catch((error) => {

        setFormData?.({ ...values });
      });
  };
  const [updatingMechanographic, setUpdatingMechanographic] = useState("");
  const handleUpdateMechanographic = async (mechanographicNumber, id) => {
    try {
      setUpdatingMechanographic(true);

      // Converte o número mecanográfico para inteiro antes de fazer a chamada da API
      const mechanographicValue = parseInt(mechanographicNumber, 10);
      const studentId = values?.id;

      // Faça a solicitação de atualização
      await axios.put(
        `${apiUrl}/students/${studentId}/mechanographic-number`,
        { mechanographicNumber: mechanographicValue },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );

      // Opcionalmente, você pode atualizar o estado ou tomar outras ações após a atualização bem-sucedida
      // Por exemplo, você pode exibir uma notificação de sucesso
      handleClick("Nº Mecanográfico Atualizado", "success");

      // Não alterar o estado `updatingMechanographic` após um envio bem-sucedido
      // setUpdatingMechanographic(false);
    } catch (error) {
      // Lidar com o erro, mostrar uma notificação de erro, etc.
      handleClick(
        error.response?.data?.message?.message ||
        "Erro ao atualizar Nº Mecanográfico",
        "error"
      );
      setUpdatingMechanographic(false);
    }
  };

  const fetchData = async () => {
    setIsLoadingSubjects(true);
    try {
      const responsePC = await axios.get(`/curricular-plan`);
      console.log("Response PC: ", responsePC);
      const courseFinded = responsePC.data?.data.find(data => data.course.id === values.courseId);
      console.log("Curricular plan: ", courseFinded);
      revertSemesterToListData(
        courseFinded.course?.courseSemesterSubjects,
        courseFinded.course?.totalNumberOfYears,
        setListData,
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingSubjects(false)
    }
  };

  useEffect(() => {
    // Use os valores de incomingDate conforme necessário
    if (incomingDate) {
      values.name = incomingDate.name;
      values.gender = incomingDate.gender;
      values.fatherName = incomingDate.fatherName;
      values.motherName = incomingDate.motherName;
      values.birthdate = incomingDate.birthdate;
      values.documentType = incomingDate.documentType;
      values.documentNumber = incomingDate.documentNumber;
      values.acessExam = incomingDate.acessExam;
      values.mechanographicNumber = incomingDate.mechanographicNumber;
      values.maritalStatus = incomingDate.maritalStatus;
      values.phone = incomingDate.phone;
      values.email = incomingDate.email;
      values.nationality = incomingDate.nationality;
      values.province = incomingDate.province;
      values.county = incomingDate.county;
      values.householdSize = incomingDate.householdSize;
      values.mechanographicNumber = incomingDate.mechanographicNumber;
      // Continue atribuindo os valores conforme necessário...

      // Certifique-se de chamar setFormData para atualizar os valores
      setFormData?.({ ...values });

      console.log("INCOMING DATE: " + incomingDate)
    }
  }, [incomingDate]);



  useEffect(() => {
    if (selectedSubjects)
      values.subjectIds = selectedSubjects;
  }, [selectedSubjects])

  console.log("INPUT VALUES: ", inputValues)

  const columnsTitle = [
    { label: "Unidade curricular", field: "name" },
    { label: "Estado", field: "status", showCheckBox: true },
  ];

  const computeNewList = (prevList, updatedValues) => {
    let newList = [...prevList];
    updatedValues.forEach((updatedData) => {
      if (updatedData) {
        const { id, creditos, dispenca } = updatedData;
        const [yearIndex, semester, dataIndex] = id.split("-");
        newList[yearIndex][semester][dataIndex] = {
          ...newList[yearIndex][semester][dataIndex],
          creditos,
          dispenca,
        };
      }
    });
    return newList;
  };

  const saveSemesterInputData = (
    updatedValues,
    setInputValues,
    removeEmptyFields = null
  ) => {
    // Compute the new list based on the previous state and the updated values
    const newList = computeNewList(listData, updatedValues);

    // Update the state with the new list
    setListData(newList);

    // Clear input values if there are updated values
    if (updatedValues?.length) {
      setInputValues([]);
    }

    console.log("Saving new list: ", newList);
    // Return the new list
    return typeof removeEmptyFields === "function"
      ? removeEmptyFields(newList)
      : newList;
  };

  const onSelectedAll = (value) => {
    setSelectedAll(value);
    console.log("Selected all: ", value);
    const firstYear = listData.find((item) => item.year === "1º Ano");

    if (firstYear) {
      // Coleta todos os subjectId do 1º e 2º semestre
      const ids = [
        ...(firstYear["1º Semestre"]?.map((subject) => subject.subjectId) || []),
        ...(firstYear["2º Semestre"]?.map((subject) => subject.subjectId) || []),
      ];


      if (value) {
        setSelectedSubjects(ids)
        values.subjectIds = ids;
      }
      else {
        setSelectedSubjects([])
        values.subjectIds = []
      }
    }
    //setSelectedSubjects({...selectedSubjects, subjectIds: [...documentTypes] });
  }

  return (
    <div className="personal-info">
      {disabled ? null : (
        <>
          <h2>{headingText}</h2>
          <Typography
            sx={{ textAlign: "justify", color: "#949494" }}
            variant="p"
          >
            {welcomeText}
          </Typography>{" "}
        </>
      )}
      <Typography
        sx={{ paddingTop: "38px", paddingBottom: "20px", color: "#003B76" }}
        variant="h3"
      >
        Dados Biográficos
      </Typography>

      <label htmlFor="profilePicInput" className="profile-pic">
        {uploadedImage ? (
          <img width="200px" src={uploadedImage} alt="Uploaded Profile Pic" />
        ) : profilePic ? (
          <img
            width="200px"
            src={`${process.env.REACT_APP_API_URL}/enrollAttachment/download/${profilePic.id}`}
            alt="Downloaded Profile Pic"
          />
        ) : (
          <img width="200px" src={profile} alt="Profile Pic" />
        )}
      </label>
      <input
        onClick={(e) => (disabled ? e.preventDefault() : null)}
        type="file"
        id="profilePicInput"
        accept="image/*"
        onChange={handleImageUpload}
        style={{ display: "none" }}
      />



      <Grid container spacing={4} mb={2}>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Select
            name="documentType"
            disabled={disabled || isAluno}
            options={DocumentTypes}
            label={
              <span>
                Tipo documento<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Textfield
            name="documentNumber"
            disabled={disabled || isAluno}
            label={
              <span>
                Nº documento<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        {!isAluno && (
          <Grid item sm={4} md={3} lg={2} xs={12} sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <Textfield
              name="acessExam"
              disabled={disabled || isAluno}
              type="number"
              label={
                <span>
                  {i18n.t("others.examTestNo")}<span className="red-asterisk">*</span>
                </span>
              }
            />
          </Grid>
        )}

        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Textfield
            name="observation"
            disabled={true} // Campo inativo
            label={
              <span>
                {i18n.t("student.studentStatus")}<span className="red-asterisk">*</span>
              </span>
            }
            value={values.observation || values.status || ''} // Preencher automaticamente
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} mb={2}>
        {keycloak?.authenticated && (
          <Grid item sm={4} md={3} lg={2} xs={12}>
            <Textfield
              name="mechanographicNumber"
              disabled={isAluno || !isSecretary}
              label={
                <span>
                  Nº Mecanográfico
                  <span className="red-asterisk">*</span>
                </span>
              }
            />
          </Grid>
        )}
        {isSecretary && (
          <Grid item sm={4} md={3} lg={2} xs={12}>
            <Button
              variant="contained"
              sx={{ width: "100%", height: "50%", mt: 3 }}
              disabled={updatingMechanographic}
              onClick={() => {
                if (!updatingMechanographic)
                  handleUpdateMechanographic(
                    values.mechanographicNumber,
                    values.id
                  );
              }}
            >
              {updatingMechanographic ? "Enviado" : "Enviar"}
            </Button>
          </Grid>
        )}
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Textfield
            disabled={!isAluno}
            name="name"
            defaultValue="Name"
            label={
              <span>
                Nome<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <DateTimePicker
            name="birthdate"
            disabled={disabled || isAluno}
            label={
              <span>
                Data Nascimento<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
      </Grid>
      {
        isLoadingSubjects ?
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress size={30} />
            <Typography variant="h4">Carrendo unidades curriculares</Typography>
          </Box>
          :
          values.observation?.toUpperCase() === "REPETENTE"
          && (
            <Grid container spacing={4}>
              <Grid item sm={4} md={4.5} lg={4.5} xs={12} mb={2}>
                {listData.filter(item => item.year === "1º Ano").map((year, index) => (
                  <Grid key={year.year}>
                    <Box sx={{ marginTop: -4 }}>
                      <CollapsibleTableVariant
                        title={year.year}
                        secondTitles={Object.keys(year).filter(
                          (key) => key !== "year"
                        )}
                        columnsTitle={columnsTitle}
                        tableData={year}
                        onIconClick={handleActivateSemester}
                        selectedSemester={selectedSemester}
                        setLastInputData={setLastInputData}
                        saveSemesterInputData={saveSemesterInputData}
                        year={year}
                        setInputValuesToDefined={setInputValues}
                        isEditing={false}
                        yearIndex={index}
                        openRow={openSemester}
                        setOpenRow={setOpenSemester}
                        values={selectedSubjects}
                        onChange={setSelectedSubjects}
                        isSelectedAll={isSelectedAll}
                        setSelectedAll={onSelectedAll}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

      <Grid container spacing={4} mb={2}>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Textfield
            disabled={disabled || isAluno}
            name="fatherName"
            label={
              <span>
                Nome Pai<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Textfield
            disabled={disabled || isAluno}
            name="motherName"
            label={
              <span>
                Nome Mãe<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Select
            disabled
            name="gender"
            options={gender}
            label={
              <span>
                Sexo<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Select
            disabled={disabled}
            options={maritalStatus}
            name="maritalStatus"
            label={
              <span>
                Estado Civil<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} mb={2}>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Select
            name="province"
            disabled={disabled || isAluno}
            onChange={(e) => {
              setMunicipe(
                province?.find((p) => p.id == e.target.value)?.municipes
              );
            }}
            options={province}
            label={
              <span>
                Província<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Select
            name="county"
            disabled={disabled || isAluno}
            options={municipe}
            label={
              <span>
                Município<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Textfield
            name="householdSize"
            disabled={disabled}
            label={
              <span>
                Dimensão de Agregado Familiar
                <span className="red-asterisk">*</span>
              </span>
            }
            type="number"
            className="your-textfield-css-class" // Add your desired CSS class here
            style={inputStyles}
          />
        </Grid>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <Textfield
            disabled={disabled || isAluno}
            name="nationality"
            label={
              <span>
                Nacionalidade<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} mb={2}>
        <Grid item sm={4} md={3} lg={2} xs={12}>
          <p
            style={{
              fontSize: "13px",
              fontWeight: "600",
              color: "#42474B",
              margin: "0px",
            }}
          >
            <span>
              Documento de Identificação<span className="red-asterisk">*</span>
            </span>
          </p>

          <UploadButton
            edit={edit}
            disabled={disabled}
            id={id}
            set={setFiles}
            name="DocIdentification"
            files={files}
            apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
            entityParamFirst={false}
          />
        </Grid>
      </Grid>

    </div >
  );
};

export default PersonalInfo;
