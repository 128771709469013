import {
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Field } from "formik";
import Textfield from "../../../components/form-components/text-field";
import "./styles/additional-info.css";
import { useState, useEffect } from "react";
import Select from "../../../components/form-components/select";
import UploadButton from "../../../components/form-components/upload-button";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { i18n } from "../../../translate/i18n";



const AdditionalInfo = ({
  id,
  acceptUserTermsProps,
  values,
  setFormData,
  setFiles,
  files,
  disabled,
  edit,
  handleClick,
}) => {
  console.log("____>", acceptUserTermsProps);

  const { acceptUseTerms, setAcceptUseTerms } = acceptUserTermsProps;
  const {
    workingInStateAgency,
    studyInclusively,
    professionalDegreeConferredBySchool,
  } = values;

  console.log("---->", acceptUseTerms);

  const { keycloak } = useKeycloak();
  const [inputTypes, setInputTypes] = useState([]);
  const [updatingEmail, setUpdatingEmail] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const isAluno =
    keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles?.includes(
      "Estudante"
    );
    const isSecretary = keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles?.includes("Secretaria")
  const headingText = isAluno
    ? 'Renovação de matrícula'
    : (isSecretary ? i18n.t("sidebar.StudentRegistration") : "Inscreva-se")
  const welcomeText = isAluno
    ? "Inscreva-se para garantir que tenhamos todas as informações necessárias para sua renovação."
    : "Inscreva-se para garantir que tenhamos todas as informações relevantes para o processo de matrícula.";
  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token, // token de autenticação
        },
      });
      console.log("Resposta da Requisição:", response);
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const updateEmail = async (email, id) => {
    try {
      setUpdatingEmail(true);
      await axios
        .put(
          `${apiUrl}/students/${id}/email`,
          { email: email },
          {
            headers: {
              Authorization: "Bearer " + keycloak.token, // token de autenticação
            },
          }
        )
        .then(() => {
          handleClick("Email Atualizado", "success");
        });
    } catch (e) {
      handleClick(e.response.data?.message.message, "error");
    } finally {
      setUpdatingEmail(false);
    }
  };
  useEffect(() => {

    getList("entry-types", setInputTypes);


  }, []);
  const [selectedOption, setSelectedOption] = useState("");



  const NextButton = styled(Button)({
    p: 3,
    backgroundColor: "#FFC107",
    color: "white",
    width: "92%",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FFCC39",
    },
  });

  const handleOptionChange = (event) => {

    console.log(selectedOption);
  };
  useEffect(() => {
    setSelectedOption(values.entryType);
  }, [values.entryType]);
  return (
    <div className="additional-info">
      {disabled ? null : (
        <>
          <h2>{headingText}</h2>
          <Typography
            sx={{ textAlign: "justify", color: "#949494" }}
            variant="p"
          >
            {welcomeText}
          </Typography>
        </>
      )}
      <Typography
        sx={{ paddingTop: "38px", paddingBottom: "30px", color: "#003B76" }}
        variant="h3"
      >
        Dados Adicionais
      </Typography>
      <Typography
        sx={{ paddingTop: "0px", paddingBottom: "15px", color: "#003B76" }}
        variant="h4"
      >
        Contacto
      </Typography>

      <Grid container spacing={4}>
        <Grid item sm={12} md={5} xs={8}>
          <Textfield
            disabled={disabled}
            name="phone"
            type="number"
            label={
              <span>
                Telefone<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={12} md={5} xs={8}>
          <Grid item>
            <Textfield
              name="email"
              label={
                <span>
                  Email<span className="red-asterisk">*</span>
                </span>
              }
            />
          </Grid>
          <Grid item>
            {disabled ? (
              <NextButton
                variant="contained"
                disabled={updatingEmail}
                onClick={() => {
                  if (!updatingEmail) updateEmail(values.email, values.id);
                }}
                sx={{ width: "98.5%", height: "40px" }}
              >
                {updatingEmail ? "Atualizando" : "Atualizar"}
              </NextButton>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Typography
        sx={{ paddingTop: "5px", paddingBottom: "15px", color: "#003B76" }}
        variant="h4"
      >
        Estudante trabalhador
      </Typography>
      <p
        style={{
          fontSize: "13px",
          fontWeight: "600",
          color: "#42474B",
          margin: "0px",
          marginBottom: "-18px",
        }}
      >
        <span>
          Estudas exclusivamente?<span className="red-asterisk">*</span>
        </span>
      </p>

      <div className="radio-button">
        <ul>
          <li className="flex-wrap">
            <Field
              disabled={disabled}
              checked={studyInclusively}
              type="radio"
              id="yes-studyExclusively"
              name="studyInclusively"
              onChange={(event) => {
                values.studyInclusively = !values.studyInclusively;
                values.professionalDegreeConferredBySchool = false;
                values.teacher = false;
                values.mediumTechnician = false;
                values.police = false;
                values.seniorTechnician = false;
                values.military = false;
                values.administrationEmployee = false;
                values.workingInStateAgency = false;
                setFormData({ ...values });
              }}
            />
            <label htmlFor="yes-studyExclusively">Sim</label>
            <div className="check"></div>
          </li>
          <li>
            <Field
              disabled={disabled}
              checked={!studyInclusively}
              type="radio"
              id="no-studyExclusively"
              name="studyInclusively"
              onChange={(event) => {
                values.studyInclusively = !values.studyInclusively;
                setFormData({ ...values });
              }}
            />
            <label htmlFor="no-studyExclusively">Não</label>
            <div className="check"></div>
          </li>
        </ul>
      </div>

      {studyInclusively === false && (
        <Grid>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "#42474B",
              margin: "0px",
              marginBottom: "-18px",
            }}
            variant="spam"
          >
            O grau profissional foi-lhe conferido por alguma escola?
            <span className="red-asterisk">*</span>
          </Typography>

          <Grid container spacing={4}>
            <Grid item sm={12} md={5} xs={8}>
              <div className="radio-button">
                <ul>
                  <li className="flex-wrap">
                    <Field
                      disabled={disabled}
                      checked={professionalDegreeConferredBySchool}
                      type="radio"
                      id="f-DegreeBySchool"
                      name="professionalDegreeConferredBySchool"
                      onChange={(event) => {
                        values.professionalDegreeConferredBySchool =
                          !values.professionalDegreeConferredBySchool;
                        setFormData({ ...values });
                      }}
                    />
                    <label htmlFor="f-DegreeBySchool">Sim</label>
                    <div className="check"></div>
                  </li>
                  <li>
                    <Field
                      disabled={disabled}
                      checked={!professionalDegreeConferredBySchool}
                      type="radio"
                      id="s-DegreeBySchool"
                      name="professionalDegreeConferredBySchool"
                      onChange={(event) => {
                        values.professionalDegreeConferredBySchool =
                          !values.professionalDegreeConferredBySchool;
                        values.schoolIssuingProfessionalDegree = "";
                        setFormData({ ...values });
                      }}
                    />
                    <label htmlFor="s-DegreeBySchool">Não</label>
                    <div className="check"></div>
                  </li>
                </ul>
              </div>
            </Grid>
            {professionalDegreeConferredBySchool === true && (
              <Grid item sm={12} md={5} xs={8}>
                <Textfield
                  sx={{
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "#42474B",
                  }}
                  disabled={disabled}
                  name="schoolIssuingProfessionalDegree"
                  label="Qual Escola?"
                />
              </Grid>
            )}
          </Grid>

          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "#42474B",
              margin: "0px",
              marginTop: "3px",
            }}
            variant="spam"
          >
            Função exercida, só deverá indicar as que forem remuneradas
            <span className="red-asterisk">*</span>
          </Typography>

          <Grid container spacing={4}>
            <Grid item sm={12} md={5} xs={5}>
              <div className="check-button">
                <ul>
                  <li>
                    <Field
                      disabled={disabled}
                      name="teacher"
                      type="checkbox"
                      id="professor"
                    />
                    <label htmlFor="professor">Professor</label>
                    <div className="check"></div>
                  </li>
                  <li className="flex-wrap">
                    <Field
                      disabled={disabled}
                      name="mediumTechnician"
                      type="checkbox"
                      id="tecnicoMedio"
                    />
                    <label htmlFor="tecnicoMedio">Técnico médio</label>
                    <div className="check"></div>
                  </li>
                  <li className="flex-wrap">
                    <Field
                      disabled={disabled}
                      name="military"
                      type="checkbox"
                      id="militar"
                    />
                    <label htmlFor="militar">Militar</label>
                    <div className="check"></div>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item sm={12} md={5} xs={4}>
              <div className="check-button second-check">
                <ul>
                  <li className="flex-wrap">
                    <Field
                      disabled={disabled}
                      name="administrationEmployee"
                      type="checkbox"
                      id="funcionarioAdministracao"
                    />
                    <label htmlFor="funcionarioAdministracao">
                      Funcionário de administração
                    </label>
                    <div className="check"></div>
                  </li>
                  <li className="flex-wrap">
                    <Field
                      disabled={disabled}
                      name="seniorTechnician"
                      type="checkbox"
                      id="tecnicoSuperior"
                    />
                    <label htmlFor="tecnicoSuperior">Técnico superior</label>
                    <div className="check"></div>
                  </li>
                  <li className="flex-wrap">
                    <Field
                      disabled={disabled}
                      name="police"
                      type="checkbox"
                      id="policia"
                    />
                    <label htmlFor="policia">Polícia</label>
                    <div className="check"></div>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>

          <p
            style={{
              fontSize: "13px",
              fontWeight: "600",
              color: "#42474B",
              margin: "0px",
              marginBottom: "-18px",
            }}
          >
            <span>
              Trabalha em algum organismo de Estado
              <span className="red-asterisk">*</span>
            </span>
          </p>
          <Grid container spacing={2}>
            <Grid item sm={12} md={5} xs={4}>
              <div className="radio-button">
                <ul>
                  <li className="flex-wrap">
                    <Field
                      disabled={disabled}
                      checked={workingInStateAgency}
                      name="workingInStateAgency"
                      type="radio"
                      id="f-workForState"
                      onChange={(event) => {
                        values.workingInStateAgency =
                          !values.workingInStateAgency;
                        setFormData({ ...values });
                      }}
                    />
                    <label htmlFor="f-workForState">Sim</label>
                    <div className="check"></div>
                  </li>
                  <li>
                    <Field
                      disabled={disabled}
                      checked={!workingInStateAgency}
                      name="workingInStateAgency"
                      type="radio"
                      id="s-workForState"
                      onChange={(event) => {
                        values.workingInStateAgency =
                          !values.workingInStateAgency;
                        values.stateAgency = "";
                        setFormData({ ...values });
                      }}
                    />
                    <label htmlFor="s-workForState">Não</label>
                    <div className="check"></div>
                  </li>
                </ul>
              </div>
            </Grid>
            {workingInStateAgency === true && (
              <Grid item sm={12} md={5} xs={8}>
                <Textfield
                  disabled={disabled}
                  name="stateAgency"
                  label="Qual?"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {!isAluno && (
        <>
          <Typography
            sx={{ paddingTop: "10px", paddingBottom: "15px", color: "#003B76" }}
            variant="h4"
          >
            Admissão ao Ensino Superior
          </Typography>

          <Grid container spacing={4}>
            <Grid item sm={12} md={5} xs={8}>
              <Textfield
                disabled={disabled}
                name="yearsOfEntryTheCourse"
                label={
                  <span>
                    Ano de ingresso ao ensino superior
                    <span className="red-asterisk">*</span>
                  </span>
                }
                type="number"
              />
            </Grid>

            <Grid item sm={12} md={5} xs={8}>
              <Select
                disabled={disabled}
                name="entryType"
                options={inputTypes}
                label={
                  <span>
                    Tipo de entrada<span className="red-asterisk">*</span>
                  </span>
                }
                onChange={handleOptionChange}
              />
              {selectedOption ===
                inputTypes?.find((i) => i.name == "Regular")?.id && (
                  <Grid item>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "#42474B",
                        margin: "0px",
                      }}
                    >
                      Atestado médico
                    </p>
                    <UploadButton
                      edit={edit}
                      disabled={disabled}
                      id={id}
                      set={setFiles}
                      name="MedicalCertificate"
                      files={files}
                      apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
                      entityParamFirst={false}
                    />
                  </Grid>
                )}

              {selectedOption ===
                inputTypes?.find(
                  (i) => i.name == "Descendente de antigo combatente"
                )?.id && (
                  <Grid item>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "#42474B",
                        margin: "0px",
                      }}
                    >
                      Atestado Médico
                    </p>
                    <Grid item>
                      <UploadButton
                        edit={edit}
                        disabled={disabled}
                        id={id}
                        set={setFiles}
                        name="MedicalCertificate"
                        files={files}
                        apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
                        entityParamFirst={false}
                      />
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#42474B",
                          margin: "10px 0px",
                        }}
                      >
                        Comprovativo de Descendente de antigo combatente
                      </p>
                      <UploadButton
                        edit={edit}
                        disabled={disabled}
                        id={id}
                        set={setFiles}
                        name="DescentFromFormerCombatant"
                        files={files}
                        apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
                        entityParamFirst={false}
                      />
                    </Grid>
                  </Grid>
                )}

              {selectedOption ===
                inputTypes?.find((i) => i.name == "Portador de deficiência")
                  ?.id && (
                  <Grid item>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "#42474B",
                        margin: "0px",
                        marginBottom: "-18px",
                      }}
                    >
                      <span>
                        Necessidade Especial
                        <span className="red-asterisk">*</span>
                      </span>
                    </p>

                    <div className="check-button">
                      <ul>
                        <li className="flex">
                          <Field
                            disabled={disabled}
                            type="checkbox"
                            id="deaf"
                            name="deaf"
                          />
                          <label htmlFor="deaf">Surdo</label>
                          <div className="check"></div>
                        </li>
                        <li>
                          <Field
                            disabled={disabled}
                            type="checkbox"
                            id="motorDeficiency"
                            name="motorDeficiency"
                          />
                          <label htmlFor="motorDeficiency">Físico Motor</label>
                          <div className="check"></div>
                        </li>
                        <li>
                          <Field
                            disabled={disabled}
                            type="checkbox"
                            id="blind"
                            name="blind"
                          />
                          <label htmlFor="blind">Cego</label>
                          <div className="check"></div>
                        </li>
                        <li>
                          <Field
                            disabled={disabled}
                            type="checkbox"
                            id="otherDeficiency"
                            name="otherDeficiency"
                          />
                          <label htmlFor="otherDeficiency">Outros</label>
                          <div className="check"></div>
                        </li>
                      </ul>
                    </div>

                    <Grid item>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#42474B",
                          margin: "10px 0px",
                        }}
                      >
                        Atestado Médico
                      </p>
                      <UploadButton
                        edit={edit}
                        disabled={disabled}
                        id={id}
                        set={setFiles}
                        name="MedicalCertificate"
                        files={files}
                        apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
                        entityParamFirst={false}
                      />
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "#42474B",
                          margin: "10px 0px",
                        }}
                      >
                        Comprovativo de portador de deficiência
                      </p>
                      <UploadButton
                        edit={edit}
                        disabled={disabled}
                        id={id}
                        set={setFiles}
                        name="ProOfOfDeficiencyCarrier"
                        files={files}
                        apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
                        entityParamFirst={false}
                      />
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Typography
            sx={{ paddingTop: "0", paddingBottom: "15px", color: "#003B76" }}
            variant="h4"
          >
            Anexos Adicionais
          </Typography>
        </>
      )}
      <Grid container spacing={2}>
        <Grid item sm={12} md={5} xs={8}>
          <p
            style={{
              fontSize: "13px",
              fontWeight: "600",
              color: "#42474B",
              margin: "0px",
            }}
          >
            Anexar Documentos Complementares
          </p>
          <UploadButton
            edit={edit}
            disabled={disabled}
            id={id}
            set={setFiles}
            name="AdditionalAttachment"
            files={files}
            apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
            entityParamFirst={false}
          />
        </Grid>
      </Grid>
      <p
        style={{
          fontSize: "13px",
          fontWeight: "600",
          color: "#42474B",
          marginBottom: "10px",
          marginTop: "71px",
        }}
      >
        <span>
          Termos de compromisso<span className="red-asterisk">*</span>
        </span>
      </p>
      <div className="responsibility">
        Assumo total responsabilidade por quaisquer declarações falsas. Estou
        ciente de que, de acordo com o regime acadêmico em vigor na UAN, posso
        enfrentar sanções que variam de 6 meses a 2 anos de suspensão por
        fornecer informações incorretas.
      </div>
      <div className="check-button">
        <ul>
          <li className="flex-wrap">
            <input
              checked={acceptUseTerms}
              name="termOfCommitment"
              type="checkbox"
              id="responsibility"
              onChange={(event) => setAcceptUseTerms(event.target.checked)}
            />
            <label htmlFor="responsibility">Confirmo</label>
            <div className="check"></div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdditionalInfo;
